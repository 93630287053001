<template>
  <section class="activation-company">
    <div class="container">
      <div class="wrapper">
        <h1 class="section-title ta--center">
          Активация
        </h1>
        <div class="divider" />
        <p class="description ta--center">
          Выберите компанию, в которой Вы приобрели Пакет услуг:
        </p>
        <div class="row">
          <div
            v-for="item in companies"
            :key="item.name"
            class="col"
          >
            <company-card
              :company="item"
              @click.native="$emit('next-slide')"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CompanyCard from '@/components/CompanyCard'

export default {
  name: 'ActivationCompany',
  components: {
    CompanyCard
  },
  data () {
    return {
      companies: [
        {
          name: 'click',
          imgType: 'svg'
        },
        {
          name: 'aero',
          imgType: 'png'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .activation-company {
    .wrapper {
      max-width: 864px;
      .description {
        margin-bottom: 48px;
        @include max-w-xs {
          margin-bottom: 24px;
        }
      }
      .row {
        justify-content: center;
        @include max-w-sm {
          flex-direction: column;
        }
        .col {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(50% - 20px);
          @include max-w-sm {
            width: calc(100% - 20px);
            margin-bottom: 20px;
          }
        }
      }
    }
  }
</style>
