<template>
  <full-page-template>
    <activation-background :active-section="activeSection" />
    <div class="p-activation p-common" :class="{ unloaded : !activeSection.length }">
      <full-page id="fullpage" ref="fullpage" :options="fullPageOptions">
        <activation-hero class="section" @next-slide="nextSlide" />
        <activation-company class="section" @next-slide="nextSlide" />
        <activation-select-product class="section" @next-slide="nextSlide" />
        <div class="section">
          <activation-page-form />
          <app-footer />
        </div>
      </full-page>
    </div>
  </full-page-template>
</template>

<script>
import ActivationBackground from '@/components/activation/inner/ActivationBackground'
import FullPageTemplate from '@/layouts/FullPageTemplate'
import AppFooter from '@/components/common/AppFooter'
import ActivationHero from '@/components/activation/ActivationHero'
import ActivationCompany from '@/components/activation/ActivationCompany'
import ActivationSelectProduct from '@/components/activation/ActivationSelectProduct'
import ActivationPageForm from '@/components/activation/ActivationPageForm'

export default {
  name: 'Activation',
  components: {
    ActivationBackground,
    FullPageTemplate,
    AppFooter,
    ActivationHero,
    ActivationCompany,
    ActivationSelectProduct,
    ActivationPageForm
  },
  data () {
    return {
      activeSection: '',
      fullPageOptions: {
        anchors: ['hero', 'company', 'select', 'form'],
        licenseKey: 'FFB008DD-B64342CE-9D967973-12897C85',
        normalScrollElements: '.scrollable-content',
        onLeave: this.onLeave,
        scrollOverflow: true,
        scrollOverflowOptions: {
          click: false
        },
        verticalCentered: false
      }
    }
  },
  mounted () {
    this.fullpage.setAllowScrolling(false, 'down')
    if (document.readyState === 'complete') {
      this.pageLoaded()
    } else {
      window.onload = () => {
        this.pageLoaded()
      }
    }
  },
  computed: {
    fullpage () {
      return this.$refs.fullpage.api
    }
  },
  watch: {
    $route (to) {
      this.fullpage.moveTo(to.hash.slice(1))
    }
  },
  methods: {
    nextSlide () {
      this.fullpage.moveSectionDown()
    },
    onLeave (origin, destination) {
      this.activeSection = destination.anchor
    },
    pageLoaded () {
      const activeSection = this.fullpage.getActiveSection()
      this.activeSection = activeSection.anchor
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
